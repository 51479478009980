
(function (window, document, com, undefined) {
    var charts = [];

    window.download = (filename, url) => {
        const anchorElement = document.createElement("a");
        anchorElement.href = url;
        anchorElement.download = (filename) ? filename : "";
        anchorElement.click();
        anchorElement.remove();
    }

    window.chart = (chartid, datax, datay, show = true) => {

        if (!show) {
            for (let i = 0; i < charts.length; i++) {
                if (charts[i][0] === chartid) {
                    charts[i][1].destroy();
                    charts.splice(i, 1);
                    break;
                }
            }
            return 0;
        }
        const xValues = datax;
        const yValues = datay;

        var chartjs = new Chart(`chart${chartid}`, {
            type: "bar",
            data: {
                labels: xValues,
                datasets: [{
                    backgroundColor: "#E03C31",
                    data: yValues
                }]
            },
            options: {
                animation: {
                    duration: 0
                },
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: 'scans'
                    },
                    ticks: {
                        precision: 0
                    }
                }
            }
        });

        charts.push([chartid, chartjs]);

        return chartjs;

    }
    
})(window, document, undefined, undefined);

const copyToClipboard = (

    function initClipboardText() {
        const textarea = document.createElement("textarea");

        // Move it off-screen.
        textarea.style.cssText = 'position: absolute; left: -99999em';

        // Set to readonly to prevent mobile devices opening a keyboard when
        // text is .select()'ed.
        textarea.setAttribute('readonly', true);

        document.body.appendChild(textarea);

        return function setClipboardText(text) {
            textarea.value = text;

            // Check if there is any content selected previously.
            const selected = document.getSelection().rangeCount > 0 ?
                document.getSelection().getRangeAt(0) : false;

            // iOS Safari blocks programmatic execCommand copying normally, without this hack.
            // https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
            if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
                const editable = textarea.contentEditable;
                textarea.contentEditable = true;
                const range = document.createRange();
                range.selectNodeContents(textarea);
                const sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
                textarea.setSelectionRange(0, 999999);
                textarea.contentEditable = editable;
            }
            else {
                textarea.select();
            }

            try {
                const result = document.execCommand('copy');

                // Restore previous selection.
                if (selected) {
                    document.getSelection().removeAllRanges();
                    document.getSelection().addRange(selected);
                }

                return result;
            }
            catch (err) {
                console.error(err);
                return false;
            }
        };
    })();

